import styles from "./index.module.scss";
import {SocialIcon as SI} from "react-social-icons";
import PropTypes from "prop-types";
import {Colour2} from "../../utils/config";

const SocialIcon = ({url}) => {
    return (
        <SI
            className={styles.SocialIcon}
            url={url}
            fgColor={Colour2}
            bgColor="none"
        />
    )
}

SocialIcon.propTypes = {
    url: PropTypes.string.isRequired
}

export default SocialIcon;