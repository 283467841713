import "./App.scss";
import {useMemo} from "react";
import {Context} from "./utils/Context";

const App = ({children}) => {
  const providerValue = useMemo(() => ({
  }), []);

  return (
    <div className="App">
        <Context.Provider value={ providerValue }>
            { children }
        </Context.Provider>
    </div>
  );
}

export default App;
