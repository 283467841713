import {SERVER_BOOKING_URL, SERVER_CONTACT_URL} from "./uris";

// Headers to be sent in each request
const commonHeaders = {
    "Content-Type": "application/json"
}

/**
 * Function to make a REST request using Fetch but with enhanced error handling as well as resolving the promise
 * returned by Fetch.
 * @param {string} url - Endpoint that the request will be directed to.
 * @param {Object} options - Fetch API options object.
 * @return {Promise<any>} - A promise that resolves to the request response.
 */
const request = async (url, options={}) => {
    options = {
        credentials: "include",
        ...options,
        headers: {...options.headers, ...commonHeaders},
        body: JSON.stringify(options.body)
    }
    return await fetch(url, options)
        .then((res) => {
            if (!res.ok) {
                throw new Error(`HTTP error - status ${res.status}`);
            }
            return res;
        })
        .then(res => res.json())
        .catch((err) => {
            console.log(err);
            throw new Error(`An error occurred: ${err.message}`);
        });
}

export const submitContactForm = async data => {
    return await request(SERVER_CONTACT_URL, {
        method: "POST",
        body: data
    });
}

export const submitBookingForm = async data => {
    return await request(SERVER_BOOKING_URL, {
        method: "POST",
        body: data
    });
}