import styles from "./index.module.scss";
import PropTypes from "prop-types";

const SubmitButton = ({text, onClick, disabled}) => {
    return (
        <button
            onClick={onClick}
            disabled={!!disabled}
            className={styles.SubmitButton}
        >
            {text}
        </button>
    )
}

SubmitButton.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}

export default SubmitButton;