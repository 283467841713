import styles from "./index.module.scss";
import {useEffect, useState} from "react";
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from "react-icons/md";
import PropTypes from "prop-types";
import {useSwipeable} from "react-swipeable";

const publicImgPath = "/assets";

const panes = [
    {
        src: "pane1.jpg",
        alt: "sample",
        top: "35%"
    },
    // {
    //     src: "pane2.jpg",
    //     alt: "sample2"
    // },
    // {
    //     src: "pane3.jpg",
    //     alt: "sample3"
    // }
];

const Pane = ({index, className, handlers}) => {
    const pane = panes[index];

    if (typeof pane === "undefined") return "";
    return (
        <div
            className={className || ""}
        >
            <div
                className={styles.ImageContainer}
                 {...handlers}
            >
                <img
                    src={`${publicImgPath}/${pane.src}`}
                    alt={pane.alt}
                    style={{top: pane.top || "50%"}}
                />
            </div>
        </div>
    )
}

Pane.propTypes = {
    index: PropTypes.number.isRequired,
    className: PropTypes.string,
    handlers: PropTypes.object,
}

const Scroller = () => {
    const [activePane, setActivePane] = useState(0);
    const [animation, setAnimation] = useState({});

    const paneCount = panes.length;

    const handlers = useSwipeable({
        onSwipedLeft: () => nextPane(),
        onSwipedRight: () => prevPane()
    });

    useEffect(() => {
        if (Object.keys(animation).length === 0) return;
        setTimeout(() => {
            setAnimation({});
        }, 1100);
    }, [animation]);

    const prevPane = () => {
        // Prevents a change if an animation is in progress
        if (Object.keys(animation).length > 0) return;

        // Triggers the animation
        setAnimation({
            index: activePane,
            action: "prev"
        });

        // Updates the index of the active pane
        setActivePane(activePane - 1);
    }

    const nextPane = () => {
        // Prevents a change if an animation is in progress
        if (Object.keys(animation).length > 0) return;

        // Triggers the animation
        setAnimation({
            index: activePane,
            action: "next"
        });

        // Updates the index of the active pane
        setActivePane(activePane + 1);
    }

    return (
        <>
            <div className={styles.Scroller}>
                {
                    activePane !== 0 &&
                    <>
                        <MdKeyboardArrowLeft
                            className={styles.BackArrow}
                            onClick={prevPane}
                        />
                        <div
                            className={styles.LeftEdge}
                            onClick={prevPane}
                        >
                        </div>
                    </>
                }

                <Pane
                    index={animation.action ? animation.index - 1 : activePane - 1}
                    className={`${animation.action === "prev" ? styles.NextPane : ""}`}
                />
                <Pane
                    index={animation.action ? animation.index : activePane}
                    className={`${styles.CenterPane} ${animation.action === "next" ? styles.SlideOutLeft : animation.action === "prev" ? styles.SlideOutRight : ""}`}
                    handlers={handlers}
                />
                <Pane
                    index={animation.action ? animation.index + 1 : activePane + 1}
                    className={`${animation.action === "next" ? styles.NextPane : ""}`}
                />

                {
                    activePane !== paneCount - 1 &&
                        <>
                            <MdKeyboardArrowRight
                                className={styles.ForwardArrow}
                                onClick={nextPane}
                            />
                            <div
                                className={styles.RightEdge}
                                onClick={nextPane}
                            >
                            </div>
                        </>
                }
            </div>
        </>
    )
}

export default Scroller;