import Scroller from "../../components/Scroller";
import styles from "./index.module.scss";
import {icons} from "../../utils/icons";

const highlights = [
    {
        heading: "Dog Walking",
        text: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."
    },
    {
        heading: "House Sitting",
        text: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."
    },
    {
        heading: "Training",
        text: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."
    }
];

const HomePage = () => {
    return (
        <>
            <Scroller />
            <div className={styles.Highlights}>
                {
                    highlights.map((data, idx) => {
                        const Icon = icons[data.heading];
                        return (
                            <div key={idx}>
                                <div className={styles.IconContainer}>
                                    <div>
                                        <Icon />
                                    </div>
                                </div>
                                <h2><span>{data.heading}</span></h2>
                                <p>{data.text}</p>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default HomePage;