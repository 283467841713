import styles from "./index.module.scss";
import {ABOUT_URL, BOOKING_URL, CONTACT_URL, HOME_URL} from "../../utils/uris";
import {nanoid} from "nanoid";
import {Link} from "react-router-dom";

const navbarItems = [
    {
        text: "Home",
        url: HOME_URL
    },
    {
        text: "About",
        url: ABOUT_URL
    },
    {
        text: "Book",
        url: BOOKING_URL

    },
    {
        text: "Contact",
        url: CONTACT_URL
    }
];

// Determines if a nav bar item/tab is active
const isActive = url => window.location.pathname === url;

const NavBar = () => {
    return (
        <div className={styles.NavBar}>
            <div>
                {
                    navbarItems.map(item => (
                        <Link
                            to={item.url}
                            className={isActive(item.url) ? styles.Active : undefined}
                            key={nanoid()}
                        >
                            {item.text}
                        </Link>
                    ))
                }
            </div>
        </div>
    )
}

export default NavBar;
