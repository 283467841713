import {Circle, GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
import styles from "./index.module.scss";
import {useEffect} from "react";
import {Colour1, Colour4} from "../../utils/config";

// Location on which the map is centered
const mapCenter = {
    lat: 51.5,
    lng: -1
}

// Business base location (center of Fleet)
const baseLocation = {
    lat: 51.277087,
    lng: -0.842642
}

// Circle radius in Kilometres
const radius = 80;

const Map = ({className}) => {
    const {isLoaded} = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    });

    useEffect(() => {
    }, []);

    if (!isLoaded) return "";

    return (
        <GoogleMap
            zoom={7}
            mapContainerClassName={`${styles.MapContainer} ${className || ""}`}
            center={mapCenter}
        >
            <Marker position={baseLocation} />
            <Circle
                center={baseLocation}
                radius={radius * 1000}
                options={{
                    fillColor: Colour4,
                    fillOpacity: 0.2,
                    strokeColor: Colour1,
                    strokeOpacity: 0.7,
                    strokeWeight: 2
                }}

            />
        </GoogleMap>
    )
}

export default Map;