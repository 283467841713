import styles from "./index.module.scss";
import {submitContactForm} from "../../utils/requests";
import {useState} from "react";
import {emailValidator, phoneNoValidator} from "../../utils/validators";
import SubmitButton from "../../components/SubmitButton";

const ContactPage = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [contactDetails, setContactDetails] = useState("");
    const [text, setText] = useState("");

    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const submit = async () => {
        // Trims relevant fields
        const data = {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            contactDetails: contactDetails.trim(),
            text: text,
        }

        if (data.firstName === "") {
            // Ensures first name is not empty
            setError("Please enter your first name.");
        } else if (data.lastName === "") {
            // Ensures last name is not empty
            setError("Please enter your last name.");
        } else if (data.contactDetails === "") {
            // Ensures contact details are not empty
            setError("Please enter your contact details.");
        } else if (data.text === "") {
            // Ensures the message is not empty
            setError("Please enter a message.");
        } else if (!phoneNoValidator(data.contactDetails) && !emailValidator(data.contactDetails)) {
            // Ensures either a valid phone number or email have been entered
            setError("Invalid contact details. Please ensure you enter a valid phone number or email.");
        } else {
            setError("");
            let genericError = false;

            try {
                setSuccess("Submitting...");
                const res = await submitContactForm(data);

                if (res.status) {
                    setSuccess("We have received your enquiry. Thank you!");
                } else {
                    genericError = true;
                }
            } catch {
                genericError = true;
            }

            if (genericError) {
                setSuccess("");
                setError(`Failed to submit form - please try again or contact us via phone on ${process.env.REACT_APP_PHONE_NO}.`);
            }
        }
    }
    return (
        <div className={styles.ContactPageContainer}>
            <div>
                <h2>Contact Us</h2>
                <p className={styles.Description}>Please fill in the form below to send us a message. We will get back to you as soon as
                    possible.</p>
                <div className={styles.ContactForm}>

                    <div className={styles.Names}>
                        <div>
                            <p className={styles.Required}>First Name</p>
                            <input
                                type="text"
                                value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                            />
                        </div>
                        <div>
                            <p className={styles.Required}>Last Name</p>
                            <input
                                type="text"
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className={styles.ContactDetails}>
                        <p className={styles.Required}>Contact (Phone/Email)</p>
                        <input
                            type="text"
                            value={contactDetails}
                            onChange={e => setContactDetails(e.target.value)}
                        />
                    </div>

                    <div>
                        <p className={styles.Required}>How can we help?</p>
                        <textarea
                            id=""
                            cols="30"
                            rows="10"
                            value={text}
                            onChange={e => setText(e.target.value)}
                        ></textarea>
                    </div>

                    <div className={styles.SubmitContainer}>
                        <SubmitButton
                            onClick={submit}
                            text="Submit"
                            disabled={success !== ""}
                        />
                    </div>

                    {
                        error !== "" ?
                            <p className={styles.ErrorMsg}>{error}</p>
                        : success !== "" &&
                            <p>{success}</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default ContactPage;