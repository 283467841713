import styles from "./index.module.scss";
import {nanoid} from "nanoid";
import {icons} from "../../utils/icons";
import Map from "../../components/Map";

const reviews = [
    {
        name: "Stephen P.",
        type: "House Sitting",
        text: "Claudia was a great help & looked after Arthur exceptionally well Would definitely request her help again",
        date: new Date("03/15/2024")
    },
    {
        name: "Luke L.",
        type: "House Sitting",
        text: "First time going away and leaving pets with a sitter, was great always kept in contact and sent photos " +
            "throughout our time away! Would definitely book again",
        date: new Date("03/03/2024")
    },
    {
        name: "Sabina G.",
        type: "Dog Walking",
        text: "Claudia is a lifesaver I had booked a walker a week beforehand who cancelled in the morning of & Claudia " +
            "happily stepped in! My dog is dog reactive & she managed him very well. He came back from the walk very happy" +
            " & not stressed. She communicates very fast. Will definitely be booking her in the future very trustworthy.",
        date: new Date("02/03/2024")
    }
];

const AboutPage = () => {
    return (
        <div className={styles.AboutPageContainer}>
            <div className={styles.AboutTop}>
                <div className={styles.Description}>
                    <div className={styles.ImageContainer}>
                        <img src="/assets/profile-img.png" alt=""/>
                    </div>
                    <div>
                        <h2>Who are we?</h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sollicitudin lobortis
                            lorem ac rutrum.
                            Morbi at scelerisque eros, non dictum tellus. Etiam ut sollicitudin sem. Nulla facilisi.
                            Aenean non libero ac lorem tempor pretium euismod sit amet urna. Praesent elementum enim
                            id pretium rutrum.
                            Nam auctor risus ac ligula accumsan, eu pretium nulla pulvinar. Etiam at dictum urna.
                            Morbi lectus lacus, sagittis eu quam tempus,
                            suscipit laoreet ligula.
                        </p>
                    </div>
                </div>
                <div className={styles.Map}>
                    <h2>Where are we available?</h2>
                    <Map className={styles.MapContainer} />
                </div>

            </div>
            <div className={styles.AboutBottom}>
                <h2>Reviews</h2>
                <div className={styles.ReviewContainer}>
                    {reviews.map(review => {
                        const Icon = icons[review.type];
                        return (
                            <div key={nanoid()}>
                                <h4>{review.name}</h4>
                                <div>
                                    <div>
                                        <Icon/>
                                        <p>{review.type}</p>
                                    </div>

                                    <p>{review.date.toLocaleDateString("en-gb")}</p>
                                </div>
                                <p>{review.text}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default AboutPage;