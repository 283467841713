import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {ABOUT_URL, BOOKING_URL, CONTACT_URL, HOME_URL} from "./utils/uris";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import BookingPage from "./pages/BookingPage";
import Header from "./components/Header";

const getPage = (PageComponent) => {
    return (
        <>
            <Header />
            <PageComponent />
        </>
    )
}

const router = createBrowserRouter([
    {
        path: HOME_URL,
        element: getPage(HomePage)
    },
    {
        path: ABOUT_URL,
        element: getPage(AboutPage)
    },
    {
        path: BOOKING_URL,
        element: getPage(BookingPage)
    },
    {
        path: CONTACT_URL,
        element: getPage(ContactPage)
    }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <App>
        <RouterProvider router={router} />
    </App>
);
