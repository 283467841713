import styles from "./index.module.scss";
import {useState} from "react";
import {nanoid} from "nanoid";
import SubmitButton from "../../components/SubmitButton";
import {submitBookingForm} from "../../utils/requests";
import * as validators from "../../utils/validators";

const periods = ["years", "months", "weeks", "days"];

// Customisable, optional text questions that are proposed to the user at the bottom of the form
const textQuestions = [
    {
        text: "How much daily exercise does your dog need?",
    },
    {
        text: "What food does your dog eat, and what is your dog's eating routine?",
    },
    {
        text: "Is your dog crate trained, and how long do you leave them for?",
    },
    {
        text: "What training do you and your dog do, and do you use treats/toys?",
    },
    {
        text: "How does your dog behave towards other dogs?",
        hint: "Playful, protective, anxious?"
    },
    {
        text: "How does your dog behave towards people?",
        hint: "Friendly, Aggressive, Affectionate?"
    },
    {
        text: "What are the biggest challenges with your dog?"
    },
    {
        text: "What are the biggest challenges with your dog?"
    }
];

const mandatoryFields = {
    owner: ["fullName", "phone", "email", "addressLine1", "city", "postcode"],
    pet: ["name", "breed", "veterinaryPractice"]
}

const BookingPage = () => {
    const [success, setSuccess] = useState("");
    const [, setError] = useState("");
    const [errorFields, setErrorFields] = useState({
        owner: [],
        pet: []
    });
    // Switches used to define the unit in which these time fields will increment in
    const [fieldPeriods, setFieldPeriods] = useState({
        age: "years",
        ownedFor: "years"
    });
    // Stores all current form data that is sent to the backend
    const [formData, setFormData] = useState({
        owner: {
            fullName: "",
            phone: "",
            email: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            county: "",
            postcode: ""
        },
        pet: {
            name: "",
            age: "",
            breed: "",
            gender: "",
            neutered: false,
            ownedFor: "",
            veterinaryPractice: ""
        },
        textQuestions: textQuestions.map(question => ({
            questionText: question.text,
            answerText: "",
        }))
    });

    // Updates a specific value in keys (that refer to objects) in formData
    const updateField = (type, field, value) => {
        // If the field is in error state and the value is no longer blank, remove the error
        if (value !== "" && errorFields[type].includes(field)) {
            setErrorFields({
                ...errorFields,
                [type]: errorFields[type].filter(val => val !== field)
            })
        }

        // Update state
        setFormData({
            ...formData,
            [type]: {
                ...formData[type],
                [field]: value
            }
        });
    }

    // Submits the form to the backend
    const submit = async () => {
        setError("");

        // ---------- Processing Owner and Pet fields ----------
        const requestData = {
            owner: formData.owner,
            pet: formData.pet
        }

        let newErrorFields = {
            owner: [],
            pet: []
        };

        // Trims the fields
        Object.keys(requestData).forEach(key => {
            const thisKeyMandatory = mandatoryFields[key];
            // For each field
            Object.keys(requestData[key]).forEach(field => {
                // (string values only)
                if (typeof requestData[key][field] === "string") {
                    // Trim the value
                    requestData[key][field] = requestData[key][field].trim();

                    // Checks if the field is mandatory and blank, constituting an error
                    if (thisKeyMandatory.includes(field) && requestData[key][field] === "") {
                        newErrorFields[key].push(field);
                    }
                }

            });
        });

        // If phone is not blank, validate the phone number format
        if (requestData.owner.phone !== "") {
            if (!validators.phoneNoValidator(requestData.owner.phone)) {
                newErrorFields.owner.push("phone");
            }
        }

        // If email is not blank, validate the email format
        if (requestData.owner.email !== "") {
            if (!validators.emailValidator(requestData.owner.email)) {
                newErrorFields.owner.push("email");
            }
        }

        // If postcode is not blank, validate postcode format
        if (requestData.owner.postcode !== "") {
            if (!validators.postcodeValidator(requestData.owner.postcode)) {
                newErrorFields.owner.push("postcode");
            }
        }

        setErrorFields(newErrorFields);

        // ---------- Processing Text Question fields ----------
        // Trims the fields
        requestData.textQuestions = formData.textQuestions.map(question => ({
            ...question,
            answerText: question.answerText.trim()
        }));

        // Exits if errors are present
        if ([...newErrorFields.owner, ...newErrorFields.pet].length > 0) {
            return;
        }

        let genericError = false;

        try {
            setSuccess("Submitting...");
            const res = await submitBookingForm(requestData);
            if (res.status) {
                setSuccess("We have received your enquiry. Thank you!");
            } else {
                genericError = true;
            }
        } catch {
            genericError = true;
        }

        if (genericError) setError(`Failed to submit form - please try again or contact us via phone on ${process.env.PHONE_NO}.`);
    }

    const PeriodSelector = ({name}) => (
        <select
            value={fieldPeriods[name]}
            onChange={e => {
                setFieldPeriods({
                    ...fieldPeriods,
                    [name]: e.target.value
                })
            }}
        >
            {
                periods.map(period => (
                    <option
                        key={nanoid()}
                        value={period}
                    >
                        {period}
                    </option>
                ))
            }
        </select>
    )

    return (
        <div className={styles.BookingPageContainer}>
            <div>
                <h2>Booking</h2>
                <p className={styles.Description}>Please fill in the form below to request a booking. We will get back
                    to
                    you as soon as
                    possible.</p>
                <div className={styles.BookingForm}>
                    <div className={styles.FormRow1}>
                        <h3>Your Details</h3>
                        <div>
                            <div>
                                <p className={styles.Required}>Full Name</p>
                                <input
                                    type="text"
                                    value={formData.owner.fullName}
                                    onChange={e => updateField("owner", "fullName", e.target.value)}
                                    className={errorFields.owner.includes("fullName") ? styles.ErrorField : ""}
                                />
                            </div>
                            <div>
                                <p className={styles.Required}>Phone Number</p>
                                <input
                                    type="text"
                                    value={formData.owner.phone}
                                    onChange={e => updateField("owner", "phone", e.target.value)}
                                    className={errorFields.owner.includes("phone") ? styles.ErrorField : ""}
                                />
                            </div>
                            <div>
                                <p className={styles.Required}>Email</p>
                                <input
                                    type="text"
                                    value={formData.owner.email}
                                    onChange={e => updateField("owner", "email", e.target.value)}
                                    className={errorFields.owner.includes("email") ? styles.ErrorField : ""}
                                />
                            </div>
                        </div>
                        <div>
                            <div>
                                <p className={styles.Required}>Address Line 1</p>
                                <input
                                    type="text"
                                    value={formData.owner.addressLine1}
                                    onChange={e => updateField("owner", "addressLine1", e.target.value)}
                                    className={errorFields.owner.includes("addressLine1") ? styles.ErrorField : ""}
                                />
                            </div>
                            <div>
                                <p>Address Line 2</p>
                                <input
                                    type="text"
                                    value={formData.owner.addressLine2}
                                    onChange={e => updateField("owner", "addressLine2", e.target.value)}
                                />
                            </div>
                            <div>
                                <p className={styles.Required}>Town/City</p>
                                <input
                                    type="text"
                                    value={formData.owner.city}
                                    onChange={e => updateField("owner", "city", e.target.value)}
                                    className={errorFields.owner.includes("city") ? styles.ErrorField : ""}
                                />
                            </div>
                            <div>
                                <p>County</p>
                                <input
                                    type="text"
                                    value={formData.owner.county}
                                    onChange={e => updateField("owner", "county", e.target.value)}
                                />
                            </div>
                            <div>
                                <p className={styles.Required}>Postcode</p>
                                <input
                                    type="text"
                                    value={formData.owner.postcode}
                                    onChange={e => updateField("owner", "postcode", e.target.value)}
                                    className={errorFields.owner.includes("postcode") ? styles.ErrorField : ""}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={styles.Line}></div>

                    <div className={styles.FormRow2}>
                        <h3>Your Pet's Details</h3>
                        <div>
                            <div>
                                <p className={styles.Required}>Name</p>
                                <input
                                    type="text"
                                    value={formData.pet.name}
                                    onChange={e => updateField("pet", "name", e.target.value)}
                                    className={errorFields.pet.includes("name") ? styles.ErrorField : ""}
                                />
                            </div>
                            <div>
                                <p className={styles.Required}>Breed</p>
                                <input
                                    type="text"
                                    value={formData.pet.breed}
                                    onChange={e => updateField("pet", "breed", e.target.value)}
                                    className={errorFields.pet.includes("breed") ? styles.ErrorField : ""}
                                />
                            </div>
                            <div>
                                <p>Age</p>
                                <input
                                    type="number"
                                    min={0}
                                    max={100}
                                    value={formData.pet.age}
                                    onChange={e => updateField("pet", "age", e.target.value)}
                                />
                                <PeriodSelector name="age"/>
                            </div>
                            <div>
                                <p>Owned For</p>
                                <input
                                    type="number"
                                    min={0}
                                    max={100}
                                    value={formData.pet.ownedFor}
                                    onChange={e => updateField("pet", "ownedFor", e.target.value)}
                                />
                                <PeriodSelector name="ownedFor"/>
                            </div>
                        </div>
                        <div>
                            <div className={styles.RadioButtons}>
                                <p>Gender</p>
                                <div>
                                    <div>
                                        <p>Male</p>
                                        <input
                                            type="radio"
                                            name="gender"
                                            checked={formData.pet.gender === "Male"}
                                            onChange={e => e.target.checked && updateField("pet", "gender", "Male")}
                                        />
                                    </div>
                                    <div>
                                        <p>Female</p>
                                        <input
                                            type="radio"
                                            name="gender"
                                            checked={formData.pet.gender === "Female"}
                                            onChange={e => e.target.checked && updateField("pet", "gender", "Female")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.RadioButtons}>
                                <p>Neutered/Spayed?</p>
                                <div>
                                    <div>
                                        <p>Yes</p>
                                        <input
                                            type="radio"
                                            name="neutered"
                                            checked={formData.pet.neutered === "Yes"}
                                            onChange={e => e.target.checked && updateField("pet", "neutered", "Yes")}
                                        />
                                    </div>
                                    <div>
                                        <p>No</p>
                                        <input
                                            type="radio"
                                            name="neutered"
                                            checked={formData.pet.neutered === "No"}
                                            onChange={e => e.target.checked && updateField("pet", "neutered", "No")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.VeterinaryPractice}>
                                <p className={styles.Required}>Veterinary Practice</p>
                                <textarea
                                    cols="30"
                                    rows="5"
                                    value={formData.pet.veterinaryPractice}
                                    onChange={e => updateField("pet", "veterinaryPractice", e.target.value)}
                                    className={errorFields.pet.includes("veterinaryPractice") ? styles.ErrorField : ""}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={styles.Line}></div>

                    <div className={styles.FormRow3}>
                        <h3>Getting to know your pet</h3>
                        <div>
                            {
                                textQuestions.map((question, idx) => (
                                    <div key={idx}>
                                        <p>{question.text}</p>
                                        {
                                            question.hint &&
                                                <p className={styles.Hint}>Playful, protective, anxious?</p>
                                        }
                                        <textarea
                                            name=""
                                            id=""
                                            rows="5"
                                            value={formData.textQuestions[idx].answerText}
                                            onChange={e => {
                                                const newTextQuestions = [...formData.textQuestions];
                                                newTextQuestions[idx] = {
                                                    ...newTextQuestions[idx],
                                                    answerText: e.target.value
                                                };
                                                setFormData({
                                                    ...formData,
                                                    textQuestions: newTextQuestions
                                                });
                                            }}
                                        ></textarea>
                                    </div>
                                ))}
                        </div>
                    </div>

                    <div className={styles.SubmitContainer}>
                        <SubmitButton
                            onClick={submit}
                            text="Submit"
                            disabled={success !== ""}
                        />
                    </div>

                    {
                        [...errorFields.owner, ...errorFields.pet].length > 0 ?
                            <p className={styles.ErrorMsg}>"Please correct the highlighted error{[...errorFields.owner, ...errorFields.pet].length > 1 ? "s" : ""} then try again."</p>
                        : success !== "" &&
                            <p>{success}</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default BookingPage;