import styles from "./index.module.scss";
import SocialIcon from "../SocialIcon";
import NavBar from "../NavBar";
import {HOME_URL} from "../../utils/uris";

const Header = () => {
    return (
        <div className={styles.HeaderContainer}>
            <div className={styles.Header}>
                <div className={styles.SocialIcons}>
                    <SocialIcon url="https://www.facebook.com" />
                    <SocialIcon url="https://www.instagram.com/highclawss" />
                    <SocialIcon url="https://www.tiktok.com/@highclaws4" />
                </div>
                <div className={styles.HeaderCenter}>
                    <a href={HOME_URL}>
                        <img
                            src="/logo.png"
                            alt="Highclaws Logo"
                        />
                        <h1>HIGHCLAWS</h1>
                    </a>
                </div>
            </div>

            <NavBar />
        </div>
    )
}

export default Header;